

import Vue from 'vue';
import { uuid } from 'vue-uuid';
import trackingServices from '@/plugins/posthog';
import AuthApi from '@/api/AuthApi';
import * as types from './mutation-types';


export const check = ({ commit }) => {
  const session = JSON.parse(localStorage.getItem('ep-session'));
  if (session) {
    commit(types.CHECK, session);
    commit(types.CHECK_FRANCECONNECT, localStorage.getItem('ep-fctoken'));
    return Promise.resolve(session);
  }
  commit(types.CHECK, null);
  return Promise.reject(new Error('No session'));
};
export const setToken = ({ commit }, payload) => {
  if (payload.token) {
    const session = { token: payload.token, login: '' };
    commit(types.LOGIN, session);
    return Promise.resolve(session);
  }
  return Promise.reject(new Error('Token undefined'));
};
export const setFranceConnectToken = ({ commit }, fcToken) => {
  commit(types.FRANCE_CONNECT_TOKEN, fcToken);
};

/**
 * Action which will try to login the user.
 *
 * @param {function} dispatch Dispatch function to trigger actions.
 * @param {function} commit  Commit function to update the store.
 * @param {Object}   payload The payload.
 */
export const login = ({ dispatch, commit }, payload) => {
  dispatch('application/startLoading', null, { root: true });
  return AuthApi.login(payload)
    .then((response) => {
      dispatch('application/finishLoading', null, { root: true });
      if (response.code === 0) {
        const session = {
          token: response.content, login: payload.username, guest: (response.accountType !== undefined && response.accountType !== 'finalized-user'),
        };
        commit(types.LOGIN, session);
        if (response.accountType === undefined || response.accountType === 'finalized-user') {
          Vue.router.push({
            name: 'home.index',
          });
        } else {
          Vue.router.push({
            name: 'guest.index',
          });
          return response.content;
        }
        return response;
      }
      return Promise.reject(new Error("Erreur d'identification"))
        .catch(() => {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('login.error.fields'),
              time: 5,
              type: 'error',
            },
            { root: true },
          );
        });
    })
    .catch((error) => {
      dispatch('application/finishLoading', null, { root: true });
      dispatch(
        'application/openSnackbar',
        {
          message: Vue.$i18n.t('login.error.fields'),
          time: 5,
          type: 'error',
        },
        { root: true },
      );

      return Promise.reject(error);
    });
};

export const loginGuest = ({ dispatch, commit }, payload) => {
  dispatch('application/startLoading', null, { root: true });
  return AuthApi.login(payload)
    .then((response) => {
      dispatch('application/finishLoading', null, { root: true });
      if (response.code === 0) {
        const session = { token: response.content, login: payload.username };
        commit(types.LOGIN, session);
        Vue.router.push({
          name: 'guest.index',
        });
        return session;
      }
      return Promise.reject(new Error("Erreur d'identification"))
        .catch(() => {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('login.error.fields'),
              time: 5,
              type: 'error',
            },
            { root: true },
          );
        });
    })
    .catch((error) => {
      dispatch('application/finishLoading', null, { root: true });
      dispatch(
        'application/openSnackbar',
        {
          message: Vue.$i18n.t('login.error.fields'),
          time: 5,
          type: 'error',
        },
        { root: true },
      );

      return Promise.reject(error);
    });
};

/**
 * Action which will try to logout the user.
 *
 * @param {function} commit Commit function to update the store.
 */
export const logout = ({ dispatch, commit, state }) => AuthApi.logout().then((response) => {
  if (response.code === 0) {
    trackingServices.logout();
    dispatch('sharings/EmptyShares', null, { root: true });
    dispatch('user/EmptyUser', null, { root: true });
    dispatch('collector/EmptyCollectors', null, { root: true });
    dispatch('documents/EmptyDocuments', null, { root: true });
    dispatch('application/resetApplication', null, { root: true });

    // logout zendesk
    window.zE('messenger', 'logoutUser');

    commit(types.LOGOUT);
    if (state.franceConnectToken) {
      let fcUrl = 'https://app.franceconnect.gouv.fr/api/v1/logout?';
      // let fcUrl = 'https://fcp.integ01.dev-franceconnect.fr/api/v1/logout?';
      fcUrl += `id_token_hint=${state.franceConnectToken}`;
      const encodedUrl = encodeURIComponent(window.location.origin + process.env.VUE_APP_LOGIN_EDOCPERSO_PUBLIC);
      fcUrl += `&state=${uuid.v4()}&post_logout_redirect_uri=${encodedUrl}`;
      document.location = fcUrl;
      // window.location = process.env.VUE_APP_LOGIN_EDOCPERSO_PUBLIC;
      return;
    }
    if (process.env.NODE_ENV === 'development') {
      Vue.router.push({
        name: 'login.index',
      });
    } else {
      window.location = process.env.VUE_APP_LOGIN_EDOCPERSO_PUBLIC;
    }
  }
});

export const logoutGuest = ({ dispatch, commit }) => AuthApi.logout().then((response) => {
  if (response.code === 0) {
    dispatch('documents/EmptySahre', null, { root: true });
    commit(types.LOGOUT);
    if (process.env.NODE_ENV === 'development') {
      Vue.router.push({
        name: 'login.index',
      });
    } else {
      window.location = process.env.VUE_APP_LOGIN_EDOCPERSO_PUBLIC;
    }
  }
});


export const setMIPIH = ({ commit }, isMIPIH) => {
  commit(types.SET_MIPIH, isMIPIH);
};


export default {
  verify({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return AuthApi.checkActivationCode(
      params.firstName,
      params.lastName,
      params.activationCode,
    ).then((response) => {
      const error = !response.content.activationCodeIsOk;
      if (error) {
        this.displayError({
          text: this.$i18n.t('activate.error.activationCode'),
          time: 5,
          type: 'error',
        });
        return false;
      }
      return true;
    });
  },

  check,
  setToken,
  setFranceConnectToken,
  login,
  logout,
  logoutGuest,
  loginGuest,
  setMIPIH,
};
